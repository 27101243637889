import React, { PropsWithChildren, useEffect } from 'react';
import { useGlobalContextFunction } from 'context/GlobalProvider';

// Children
import { CarouselSection } from './components/carousel-section';
import { ServicesSection } from './components/services-section';
import { ProjectsSection } from './components/projects-section';

// Components
import { HeadlineText } from 'components/headline-text';
import { ClientsSlider } from 'pages/home/components/clients-slider';

import './Home.css';

const Home: React.FC<PropsWithChildren> = () => {
  const { setCurrentPage } = useGlobalContextFunction();

  useEffect(() => {
    setCurrentPage('home');
  }, [setCurrentPage]);

  return (
    <div className='home__container'>
      <CarouselSection />
      <ServicesSection />
      <ProjectsSection />

      <div className='client__section'>
        <HeadlineText text='PT. Devta merupakan perusahaan Printing terpercaya selama 20 tahun' />
        <ClientsSlider />
      </div>
    </div>
  );
};

export default Home;
