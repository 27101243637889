import React, { PropsWithChildren } from 'react';

import './HeadlineText.css';

type HeadlineTypeProps = {
  text: string;
};

const HeadlineText: React.FC<PropsWithChildren<HeadlineTypeProps>> = ({
  text,
}) => {
  return (
    <div className='headline__text'>
      <span>{text}</span>
    </div>
  );
};

export default HeadlineText;
