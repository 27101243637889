import React, { Suspense, useEffect, useState } from 'react';
import ChunkLoadErrorBoundary from 'common/errors/ChunkLoadErrorBoundary';
import ModalPlaceHolder from './ModalPlaceHolder';

interface Props {
  /**
   * Component Modal. You need to import wrapped by React.lazy
   */
  component: React.ComponentType<React.PropsWithChildren<any>>;
  /**
   * Visible modal. please makesure default visible is false in first time.
   */
  visible?: boolean;
}

const LazyModal: React.FC<React.PropsWithChildren<Props>> = ({
  component: Component,
  visible,
  ...rest
}) => {
  /**
   * Determine modal already open or not
   */
  const [alreadyOpen, setAlreadyOpen] = useState(false);

  useEffect(() => {
    if (visible) {
      setAlreadyOpen(true);
    }
  }, [visible]);

  // just return null if modal not already open at the first time
  if (!alreadyOpen) return null;

  // Download and Render component Modal if alreadyOpen.
  // If component already downloaded, the React.lazy not download again.
  return (
    <ChunkLoadErrorBoundary>
      <Suspense fallback={<ModalPlaceHolder />}>
        <Component visible={visible} {...rest} />
      </Suspense>
    </ChunkLoadErrorBoundary>
  );
};

export default LazyModal;
