import React, { useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'react-feather';
import classNames from 'classnames';
import { useScrollBlock } from 'hooks';

import './Modal.css';

interface ModalProps {
  children: ReactNode;
  /**
   * Control modal visibility
   */
  visible: boolean;
  /**
   * Duration for slide animation (ms)
   */
  slideDuration?: number;
  /** Handle modal scrolling
   * In case you need modal to scrolling,
   */
  lockScrolling?: boolean;
  /**
   * Callback called when the close button or mask is clicked
   */
  onClose?: React.MouseEventHandler<HTMLDivElement>;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  visible,
  slideDuration = 0,
  lockScrolling = true,
  onClose
}) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [modalAnimation, setModalAnimation] = useState<boolean>(false);
  // tell us where animation is at
  // this state only consider when `animated` props is true
  // 1: the menu not show in the screen. which mean `visible` props is false
  // 2: the menu show in the Screen. which mean visible is true. then the menu ready to animating close
  const [animationStep, setAnimationStep] = useState<1 | 2>(1);

  // Hook to block/allow scroll
  const [scrollBlock, allowScroll] = useScrollBlock();

  useEffect(() => {
    let timeout: any;

    if (!visible) {
      setModalAnimation(visible);

      if (animationStep === 2) {
        timeout = setTimeout(() => {
          // Allow scroll when modal closed
          allowScroll();

          setVisibleModal(visible);

          // reset animation step to be 1 again
          setAnimationStep(1);
        }, slideDuration);
      } else {
        setVisibleModal(visible);
      }
      return;
    }

    setVisibleModal(visible);

    // Lock scrolling default true, if true we lock the scrolling.
    timeout = setTimeout(() => {
      if (lockScrolling) {
        // Prevent scrolling when modal is open
        scrollBlock();
      }

      // set animating step to be 2
      setAnimationStep(2);
      setModalAnimation(visible);
    }, slideDuration);

    return () => {
      // Allow scroll on un-mounting component
      allowScroll();

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    visible,
    lockScrolling,
    slideDuration,
    animationStep,
    allowScroll,
    scrollBlock
  ]);

  if (!visibleModal) return null;

  return ReactDOM.createPortal(
    <div
      className={classNames({
        modal: true,
        modal__visible: modalAnimation
      })}
    >
      <div className="modal__mask" onClick={onClose} />
      <div role="dialog" aria-modal="true" className="modal__body">
        <div className="modal__close-btn">
          <span onClick={onClose}>
            <X color="#666" size={16} />
          </span>
        </div>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
