import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import { useWindowResize } from 'hooks';
import { projectData } from 'pages/project/constants';

import styles from './ProjectsSection.module.css';
import 'swiper/css';
import 'swiper/css/navigation';

const ProjectsSection: React.FC = () => {
  const { width } = useWindowResize();

  const slidesPerView = (): number => {
    if (width >= 1024) return 3;
    if (width > 767 && width < 1024) return 2;
    return 1;
  };

  return (
    <div className={styles['projects__container']}>
      <h1 className={styles['project__title']}>Proyek Kami</h1>
      <div className={styles['project__text']}>
        <p>
          Apakah kamu membutuhkan jasa percetakan digital printing yang
          berkualitas dan terpercaya?
        </p>
        <h4>DEVTA adalah solusinya!</h4>
        <p>
          Berikut berbagai project dari berbagai klien DEVTA sebagai referensi
          kamu.
        </p>
      </div>
      <Swiper
        loop
        autoplay={{ delay: 3000 }}
        navigation={true}
        spaceBetween={30}
        modules={[Navigation, Autoplay]}
        slidesPerView={slidesPerView()}
      >
        {projectData.map((project, idx) => (
          <SwiperSlide key={idx}>
            <>
              <div className={styles['border__projects-img']}>
                <img src={project.image} alt={project.name} />
              </div>
              <p className={styles['projects__title']}>{project.name}</p>
            </>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProjectsSection;
