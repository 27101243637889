import React, { useState, useEffect } from 'react';
import { useToggle } from 'hooks';
import { useGlobalContextFunction } from 'context/GlobalProvider';
import { HeadlineText } from 'components/headline-text';
import WorkshopDetailModal from './components/worksop-detail-modal';
import { workshopData } from './constants';

import './Workshop.css';

const Workshop: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string>('');

  const { setCurrentPage } = useGlobalContextFunction();

  const { active, toggleActive } = useToggle();

  const handleSelectImage = (image: string) => {
    setSelectedImage(image);
    toggleActive();
  };

  useEffect(() => {
    setCurrentPage('workshop');
  }, [setCurrentPage]);
  
  return (
    <div className="workshop__container">
      <div className="workshop__top-desc">
        <p>
          Kami menyediakan layanan cetak digital printing dengan menggunakan
          teknologi terkini yang dapat menghasilkan cetakan berkualitas tinggi.
        </p>
        <p>
          Berikut beberapa dokumentasi workshop yang dimiliki DEVTA sebagai
          referensi kamu.
        </p>
      </div>
      <div className="workshop__image-wrapper">
        {workshopData.map((workshop, idx) => (
          <img
            key={`workshop-${idx}`}
            src={workshop.image}
            alt={workshop.name}
            onClick={() => handleSelectImage(workshop.image)}
          />
        ))}
      </div>
      <div className="workshop__headline-text">
        <HeadlineText text="PT. Devta merupakan perusahaan Printing terpercaya selama 20 tahun" />
      </div>

      {!!selectedImage && (
        <WorkshopDetailModal
          showModal={active}
          onClose={toggleActive}
          selectedImage={selectedImage}
        />
      )}
    </div>
  );
};

export default Workshop;
