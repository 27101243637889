import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Pages
import { Home } from 'pages/home';
import { Philosophy, Advantage } from 'pages/about-us';
import { Project } from 'pages/project';
import { ProjectDetail } from 'pages/project-detail';
import { Workshop } from 'pages/workshop';
import { Clients } from 'pages/clients';
import { ContactUs } from 'pages/contact-us';

// Independent components
import { Navbar } from 'components/navbar';
import { Footer } from 'components/footer';
import { WhatsAppWidget } from 'components/whatsapp-widget';

// Util function
import ScrollToTop from 'utils/ScrollToTop';

const RouteApp: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />

      {/* Routes start here */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/filosofi' element={<Philosophy />} />
        <Route path='/keunggulan' element={<Advantage />} />
        <Route path='/project' element={<Project />} />
        <Route path='/project/:project' element={<ProjectDetail />} />
        <Route path='/workshop' element={<Workshop />} />
        <Route path='/clients' element={<Clients />} />
        <Route path='/contactus' element={<ContactUs />} />
      </Routes>
      {/* Routes end here */}

      <WhatsAppWidget />
      <Footer />
    </Router>
  );
};

export default RouteApp;