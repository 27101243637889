import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, ChevronDown } from 'react-feather';
import classNames from 'classnames';
import { useGlobalContextState } from 'context/GlobalProvider';
import { useToggle, useWindowResize } from 'hooks';
import { LeftMenu } from './components';
import { NavMenu, SubMenu, navMenu } from './constants';
import DevtaLogo from 'assets/logo/devta-logo.png';

import './Navbar.css';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowResize();

  const { currentPage } = useGlobalContextState();

  const [subMenuActive, setSubMenuActive] = useState<number | undefined>();

  const { active: leftMenuActive, toggleActive: toggleLeftMenu } = useToggle();

  /** Determine of device user is moblie */
  const isMobile = width <= 1023;

  const handleMouseEnter = (menu: NavMenu) => {
    setSubMenuActive(menu.id);
  };

  const handleMouseLeave = () => {
    setSubMenuActive(undefined);
  };

  const handleNavigate = (menu: NavMenu) => {
    if (menu?.subMenu) return;

    if (menu?.link) {
      return navigate(menu?.link);
    }
    return;
  };

  const handleSubMenu = (subMenu: SubMenu) => {
    if (!!subMenuActive) {
      setSubMenuActive(undefined);
    }

    return navigate(subMenu.link);
  };

  return (
    <div className="nav__container">
      <div className="nav__logo">
        <img src={DevtaLogo} alt="Devta" />
      </div>

      {isMobile ? (
        <div className="nav__menu-mobile" onClick={toggleLeftMenu}>
          <Menu />
        </div>
      ) : (
        <div className="nav__menu">
          {navMenu.map((menu, idx) => (
            <div
              key={`${menu}-${idx}`}
              className={classNames('nav__menu-list', {
                active:
                  currentPage === menu.slug ||
                  menu?.subMenu?.some((value) => value.slug === currentPage)
              })}
              onClick={() => handleNavigate(menu)}
              onMouseEnter={() => handleMouseEnter(menu)}
              onMouseLeave={handleMouseLeave}
            >
              {menu.name}
              {menu.subMenu && (
                <>
                  <span className="nav__submenu-arrow">
                    <ChevronDown size={20} />
                  </span>
                  {subMenuActive === menu.id && (
                    <div className={`dropdown__wrapper ${menu?.slug}`}>
                      {[
                        ...menu.subMenu.map((subMenu, idx) => (
                          <div key={idx} className="dropdown__options">
                            <input type="radio" className="radio" />
                            <label
                              className={classNames({
                                active: currentPage === subMenu.slug
                              })}
                              onClick={() => handleSubMenu(subMenu)}
                            >
                              {subMenu.name}
                            </label>
                          </div>
                        ))
                      ]}
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      )}

      <LeftMenu visible={leftMenuActive} onClose={toggleLeftMenu} />
    </div>
  );
};

export default Navbar;
