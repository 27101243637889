import React from 'react';
import { Loading } from 'common/lazy-load/components';
import { Modal } from 'components/modal';

const ModalPlaceHolder: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Modal visible={true}>
      <Loading />
    </Modal>
  );
};

export default ModalPlaceHolder;
