import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import { useGlobalContextFunction } from 'context/GlobalProvider';
import { HeadlineText } from 'components/headline-text';
import { devtaHistories, devtaVision, devtaMission } from './constant';
import VisionMissionImg from './assets/vision-mission.png';

import 'swiper/css';
import './Philosophy.css';

const Philosophy: React.FC = () => {
  const { setCurrentPage } = useGlobalContextFunction();

  useEffect(() => {
    setCurrentPage('philosophy');
  }, [setCurrentPage]);

  return (
    <div className="philosophy__container">
      <div className="philosophy__slider">
        <Swiper
          loop
          autoplay={{ delay: 3500 }}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation, Autoplay]}
        >
          <SwiperSlide>
            <div className="slider__office office1" />
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider__office office2" />
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider__office office3" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="philosophy__content">
        <h1>PT. Devta Raih Cita</h1>
        <div className="philosophy__history">
          {devtaHistories.map((devta, index) => (
            <div className="philosophy__history-item" key={index}>
              <span>{devta.year}</span>
              <p>{devta.history}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="philosophy__service">
        <p>
          Kami menyediakan layanan cetak digital printing dengan menggunakan
          teknologi terkini yang dapat menghasilkan cetakan berkualitas tinggi
          dengan warna yang jelas dan tajam. Selain itu, kami juga menyediakan
          berbagai jenis bahan cetak, mulai dari kertas berkualitas tinggi
          hingga bahan sintetis yang tahan lama dan waterproof. Kami selalu
          mengutamakan kepuasan pelanggan dengan memberikan layanan cepat dan
          tepat waktu serta harga yang bersaing. Segera hubungi kami untuk
          mendapatkan penawaran harga terbaik untuk kebutuhan cetak digital
          printing Anda!{' '}
        </p>
      </div>
      <div className="vision__mission">
        <img className="illust" src={VisionMissionImg} alt="Illust" />
        <div className="vision__mission-list">
          <h1>Visi</h1>
          <span>
            {devtaVision.map((vision, index) => (
              <ul key={index}>
                <li>{vision}</li>
              </ul>
            ))}
          </span>
        </div>
        <div className="vision__mission-list">
          <h1>Misi</h1>
          <span>
            {devtaMission.map((mission, index) => (
              <ul key={index}>
                <li>{mission}</li>
              </ul>
            ))}
          </span>
        </div>
      </div>
      <HeadlineText text="PT. Devta merupakan perusahaan Printing terpercaya selama 20 tahun" />
    </div>
  );
};

export default Philosophy;
