import React, { 
    createContext, 
    PropsWithChildren,
    useContext, 
    useState, 
  } from 'react';
  
  type PageType =
    | 'home'
    | 'philosophy'
    | 'advantage'
    | 'project'
    | 'banner'
    | 'wallpaper-branding'
    | 'car-branding'
    | 'baliho'
    | 'large-sticker'
    | 'printing-a3'
    | 'display-banner'
    | 'event-organizer'
    | 'spanduk'
    | 'sablon-kaos'
    | 'workshop'
    | 'clients'
    | 'contactus'
    | string;
  
  interface GlobalStateType {
    currentPage: PageType;
  };
  
  interface GlobalFunctionType {
    setCurrentPage: React.Dispatch<React.SetStateAction<PageType | string>>;
  };
  
  export const GlobalStateContext = createContext<GlobalStateType | undefined>(
    undefined
  );
  
  export const GlobalFunctionContext = createContext<GlobalFunctionType | undefined> (
    undefined
  );
  
  const GlobalProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [currentPage, setCurrentPage] = useState<PageType | string>('home');
  
    return (
      <GlobalStateContext.Provider value={{currentPage}}>
        <GlobalFunctionContext.Provider value={{setCurrentPage}}>
          {children}
        </GlobalFunctionContext.Provider>
      </GlobalStateContext.Provider>
    );
  };
  
  export const useGlobalContextState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
      throw new Error('useGlobalContextState must be used within a GlobalProvider');
    }
    return context;
  };
  
  export const useGlobalContextFunction = () => {
    const context = useContext(GlobalFunctionContext);
    if (!context) {
      throw new Error('useGlobalContextFunction must be used within a GlobalProvider');
    }
    return context;
  };
  
  export default GlobalProvider;
  