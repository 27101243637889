import React from 'react';
import { SubProduct } from 'pages/project/constants';
import style from './DetailWithSubProduct.module.css';

interface Props {
  subProduct: SubProduct[];
}

const DetailWithSubProduct: React.FC<Props> = ({ subProduct }) => {
  return (
    <div className={style['detail__subproduct-wrapper']}>
      {subProduct.map((sub, idx) => (
        <div key={idx} className={style['subproduct__item-wrapper']}>
          <div className={style['subproduct__item-name']}>
            <p>{sub.name}:</p>
            {sub.desc?.map((value, idx) => (
              <ul key={`value-${idx}`}>
                <li>{value}</li>
              </ul>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailWithSubProduct;
