export const devtaHistories = [
  {
    year: '1991',
    history:
      'Dimulai dari usaha Home Industri dengan nama Media Inti Promosindo (MIP) di tahun 1991 berlokasi di Jl. Harun Raya Kebon Jeruk Jakarta Barat. MIP bergerak di bidang produksi dan pembuatan barang-barang produksi, seperti spanduk, umbul-umbul, flag chain dan kaos.'
  },
  {
    year: '2003',
    history:
      'Untuk meningkatkan pelayanan kepada klien yang lebih baik dan memperluas order perusahaan besar, maka pada tahun 2003 legalitasnya dinaikkan menjadi Perseroan Terbatas dengan nama PT Devta Raih Cita.'
  },
  {
    year: '2007',
    history:
      'Pada tahun 2007 dibangun sebuah workshop dan kantor di atas tanah seluas 2397 m2 di daerah belakang perum Banjar Wijaya, Cipete, Tangerang. Seiring perkembangan zaman, pada awal 2007 PT Devta Raih Cita melakukan ekspansi ke digital printing yang menghasilkan barang-barang sesuai kebutuhan klien. Dengan pengalaman lebih dari 20 tahun di industri percetakan digital printing, kami telah melayani berbagai jenis proyek dari pelanggan yang berasal dari berbagai sektor dan ukuran bisnis.'
  }
];

export const devtaVision = [
	'Menjadi perusahaan advertising yang terbaik dalam kualitas.',
	'Memberikan pelayanan kepada klien semaksimal mungkin.',
];

export const devtaMission = [
	'Mencetak barang-barang promosi dengan kualitas yang prima.',
	'Memberikan layanan tepat waktu dalam pengiriman barang.',
	'Memberikan solusi kepada klien dalam bentuk barang-barang promosi yang diinginkan.',
	'Memberikan pelayanan kepada klien dari mulai pra-cetak sampai kepada pemasangan.',
];