import Process1 from '../assets/process-1.png';
import Process2 from '../assets/process-2.png';
import Process3 from '../assets/process-3.png';
import Process4 from '../assets/process-4.png';
import Process5 from '../assets/process-5.png';
import Process6 from '../assets/process-6.png';
import Process7 from '../assets/process-7.png';

interface DevtaAdvantages {
  title: string;
  desc: string;
  desc2?: string;
}

interface ProcessOrder {
  id: number;
  process_name: string;
  process_img: string;
}

export const devtaAdvantages: DevtaAdvantages[] = [
  {
    title: 'Kualitas cetakan yang baik',
    desc: 'Kami menggunakan teknologi cetak digital printing terkini yang dapat menghasilkan cetakan dengan kualitas yang sangat baik.',
    desc2: 'Hasil cetakan yang tajam, detail yang jelas, dan warna yang cerah.'
  },
  {
    title: 'Berbagai jenis bahan cetak',
    desc: 'Kami menyediakan berbagai jenis bahan cetak mulai dari kertas, banner dan kain spanduk berkualitas tinggi hingga bahan sintetis yang tahan lama dan waterproof.'
  },
  {
    title: 'Harga yang bersaing',
    desc: 'Kami menawarkan harga yang bersaing untuk jasa digital printing.',
    desc2: 'Kami selalu berusaha memberikan harga terbaik tanpa mengurangi kualitas cetakan.'
  },
  {
    title: 'Layanan cepat dan tepat waktu',
    desc: 'Kami memahami betapa pentingnya waktu dalam setiap proyek cetak.',
    desc2: 'Oleh karena itu, kami selalu berusaha untuk memberikan layanan cepat dan tepat waktu kepada pelanggan.'
  },
  {
    title: 'Pengalaman dan keahlian',
    desc: 'Dengan pengalaman hampir 20tahun, kami memiliki tim yang berpengalaman dan ahli dalam bidang digital printing.',
    desc2: 'Tim kami dapat membantu kamu memilih bahan cetak yang tepat, memberikan saran desain, dan menyelesaikan proyek cetak dengan hasil yang memuaskan.'
  }
];

export const processOrder: ProcessOrder[] = [
  {
    id: 1,
    process_name: 'Briefing',
    process_img: Process1
  },
  {
    id: 2,
    process_name: 'Pengecekan dan Approval File (Visual Design)',
    process_img: Process2
  },
  {
    id: 3,
    process_name: 'Proyeksi Timeline & Pengerjaan',
    process_img: Process3
  },
  {
    id: 4,
    process_name: 'Test Printing',
    process_img: Process4
  },
  {
    id: 5,
    process_name: 'Produksi Cetak',
    process_img: Process5
  },
  {
    id: 6,
    process_name: 'Quality Control',
    process_img: Process6
  },
  {
    id: 7,
    process_name: 'Pengiriman / Pick Up Order',
    process_img: Process7
  }
];

export const machines = [
  'Outdoor Taimes KM 512i',
  'Indoor Mimimaki',
  'UV GEL XENONS',
  'Konika Minolta AP 2070',
  'Cutting Mimaki',
  'Cutting A3',
  'Vulkan FC 500-VC Flatbed',
  'Mesin Laminasi A3',
  'Mesin Laminasi Crystal Sign'
];
