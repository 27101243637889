import AlfamartLogo from '../assets/alfamart.png';
import PropanLogo from '../assets/propan.png';
import BFILogo from '../assets/bfi-finance.png';
import LippoMallPuriLogo from '../assets/lippo-mall-puri.png';
import MitsuyamaLogo from '../assets/mitsuyama.png';
import IsoloiLogo from '../assets/isoloi.png';
import CitrakaryaLogo from '../assets/citakarya-pilar-media.png';
import CNMLogo from '../assets/cnm-adv.png';
import GoldenCityLogo from '../assets/golden-city.png';
import CitraGardenPuriLogo from '../assets/citra-garden-puri.png';
import OrangTuaLogo from '../assets/orang-tua.png';
import BintaroParkviewLogo from '../assets/bintaro-parkview.png';
import SpringHillLogo from '../assets/springhill.png';
import HabitatLogo from '../assets/habitat.png';
import EnergizerLogo from '../assets/energizer.png';
import VivoLogo from '../assets/vivo.png';
import SerangCityLogo from '../assets/serang-city.png';
import Mitra2000Logo from '../assets/mitra2000.png';
import DepoBangunanLogo from '../assets/depo-bangunan.png';
import Mitra10Logo from '../assets/mitra10.png';
import AyanaLogo from '../assets/ayana.png';
import GoldenHillsLogo from '../assets/golden-hills.png';
import STMIKGlobalLogo from '../assets/stmik-global.png';
import GlobalSevillaLogo from '../assets/global-sevilla.png';
import DJPLogo from '../assets/djp.png';
import GrandDutaGardenLogo from '../assets/grand-duta-garden.png';
import WeberLogo from '../assets/weber.png';
import GSSupermarketLogo from '../assets/gs-supermarket.png';

interface ClientLogos {
  id: number;
  image: string;
  alt: string;
  name: string;
}

export const clientLogos: ClientLogos[] = [
  {
    id: 1,
    image: AlfamartLogo,
    alt: 'Alfamart',
    name: 'Alfamart'
  },
  {
    id: 2,
    image: PropanLogo,
    alt: 'Propan',
    name: 'Propan'
  },
  {
    id: 3,
    image: BFILogo,
    alt: 'BFI Finance',
    name: 'BFI Finance'
  },
  {
    id: 4,
    image: LippoMallPuriLogo,
    alt: 'Lippo Mall Puri',
    name: 'Lippo Mall Puri'
  },
  {
    id: 5,
    image: MitsuyamaLogo,
    alt: 'Mitsuyama',
    name: 'Mitsuyama'
  },
  {
    id: 6,
    image: IsoloiLogo,
    alt: 'Isoloi',
    name: 'Isoloi'
  },
  {
    id: 7,
    image: CitrakaryaLogo,
    alt: 'Citrakarya Pilar Media',
    name: 'Citrakarya Pilar Media'
  },
  {
    id: 8,
    image: CNMLogo,
    alt: 'CNM Advertising',
    name: 'CNM Advertising'
  },
  {
    id: 9,
    image: GoldenCityLogo,
    alt: 'Golden City',
    name: 'Golden City'
  },
  {
    id: 10,
    image: CitraGardenPuriLogo,
    alt: 'Citra Garden Puri',
    name: 'Citra Garden Puri'
  },
  {
    id: 11,
    image: OrangTuaLogo,
    alt: 'Orang Tua Group',
    name: 'Orang Tua Group'
  },
  {
    id: 12,
    image: BintaroParkviewLogo,
    alt: 'Bintaro Parkview',
    name: 'Bintaro Parkview'
  },
  {
    id: 13,
    image: SpringHillLogo,
    alt: 'Spring Hill',
    name: 'Spring Hill'
  },
  {
    id: 14,
    image: HabitatLogo,
    alt: 'Habitat',
    name: 'Habitat'
  },
  {
    id: 15,
    image: EnergizerLogo,
    alt: 'Energizer',
    name: 'Energizer'
  },
  {
    id: 16,
    image: VivoLogo,
    alt: 'Vivo',
    name: 'Vivo'
  },
  {
    id: 17,
    image: SerangCityLogo,
    alt: 'Serang City',
    name: 'Serang City'
  },
  {
    id: 18,
    image: Mitra2000Logo,
    alt: 'Mitra2000',
    name: 'Mitra2000'
  },
  {
    id: 19,
    image: DepoBangunanLogo,
    alt: 'Depo Bangunan',
    name: 'Depo Bangunan'
  },
  {
    id: 20,
    image: Mitra10Logo,
    alt: 'Mitra10',
    name: 'Mitra10'
  },
  {
    id: 21,
    image: AyanaLogo,
    alt: 'Ayana',
    name: 'Ayana'
  },
  {
    id: 22,
    image: GoldenHillsLogo,
    alt: 'Golden Hills',
    name: 'Golden Hills'
  },
  {
    id: 23,
    image: STMIKGlobalLogo,
    alt: 'STMIK Global',
    name: 'STMIK Global'
  },
  {
    id: 24,
    image: GlobalSevillaLogo,
    alt: 'Global Sevilla',
    name: 'Global Sevilla'
  },
  {
    id: 25,
    image: DJPLogo,
    alt: 'DJP',
    name: 'DJP'
  },
  {
    id: 26,
    image: GrandDutaGardenLogo,
    alt: 'Grand Duta Garden',
    name: 'Grand Duta Garden'
  },
  {
    id: 27,
    image: WeberLogo,
    alt: 'Weber',
    name: 'Weber'
  },
  {
    id: 28,
    image: GSSupermarketLogo,
    alt: 'GS Supermarket',
    name: 'GS Supermarket'
  }
];
