import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import './CarouselSection.css';

const CarouselSection: React.FC = () => {
  return (
    <div className="carousel__container">
      <Swiper
        loop
        slidesPerView={1}
        navigation={false}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        spaceBetween={30}
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide>
          <div className="slider__image topbar1" />
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider__image topbar2" />
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider__image topbar3" />
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider__image topbar4" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default CarouselSection;
