import { lazy } from 'react';
import { LazyModal } from 'common/lazy-modal';
import { Props } from './WorkshopDetailModal';

const Modal = lazy(() => import('./WorkshopDetailModal'));

const WorkshopDetailModal = (props: Props) => {
  return <LazyModal component={Modal} visible={props.showModal} {...props} />;
};

export default WorkshopDetailModal;
