import React, { useEffect } from 'react';
import { useGlobalContextFunction } from 'context/GlobalProvider';
import { HeadlineText } from 'components/headline-text';
import { devtaAdvantages, processOrder, machines } from './constants';

import './Advantage.css';

const Advantage: React.FC = () => {
  const { setCurrentPage } = useGlobalContextFunction();

  useEffect(() => {
    setCurrentPage('advantage');
  }, [setCurrentPage]);

  return (
    <div className="advantage__container">
      <div className="advantage__section">
        <h1 className="advantage__header-text">Kenapa Memilih Devta?</h1>
        <div className="advantage__list-wrapper">
          {devtaAdvantages.map((item, index) => (
            <React.Fragment key={index}>
              <div className="advantage__chip">{item.title}</div>
              <div className="advantage__desc">
                <p>{item.desc}</p>
                {item?.desc2 && <p>{item.desc2}</p>}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="process__order-section">
        <div className="process__chip">Proses Pekerjaan</div>
        <div className="process__list-wrapper">
          {processOrder.map((process, index) => (
            <div key={index} className="process__list">
              <span className="round__number">{process.id}</span>
              <div className="process__img-wrapper">
                <img src={process.process_img} alt="proses" />
                <p>{process.process_name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="machine__section">
        <div className="advantage__chip">Mesin-mesin Pendukung</div>
        <div className="machine__text">
          <p>
            Selain beberapa kelebihan di atas kami juga dilengkapi dengan
            mesin-mesin generasi modern di antaranya :
          </p>
        </div>
        <div className="machine__list-wrapper">
          {machines.map((machine, index) => (
            <span key={index}>{machine}</span>
          ))}
        </div>
        <div className="machine__text">
          <p>
            DEVTA akan terus berkembang dengan menciptakan inovasi yang lebih
            baik, serta terus mempertahankan kualitas baik kepada customer.
          </p>
        </div>
      </div>
      <div className="advantage__headline-text">
        <HeadlineText text="PT. Devta merupakan perusahaan Printing terpercaya selama 20 tahun" />
      </div>
    </div>
  );
};

export default Advantage;
