import {
  ContactFormValues,
  ContactFormErrors
} from 'pages/contact-us/ContactUs';

// Validator email by regex
const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

type FieldValidation = {
  fieldName: keyof ContactFormValues;
  emptyError: string;
  minError?: string;
  min?: number;
  validator?: (value: string) => boolean;
  validatorError?: string;
};

const fieldValidations: FieldValidation[] = [
  {
    fieldName: 'name',
    emptyError: 'Kolom nama harus diisi',
    min: 3,
    minError: 'Nama minimal 3 huruf'
  },
  {
    fieldName: 'userEmail',
    emptyError: 'Email harus diisi',
    validator: isValidEmail,
    validatorError: 'Format email tidak sesuai'
  },
  {
    fieldName: 'subject',
    emptyError: 'Subject harus diisi',
    min: 3,
    minError: 'Subject minimal 3 huruf'
  },
  {
    fieldName: 'message',
    emptyError: 'Mohon isi pesan',
    min: 10,
    minError: 'Isi pesan kamu minimal 10 karakter'
  }
];

export const validateForm = async (
  stateErrorForm: ContactFormValues,
  dispatcher: React.Dispatch<React.SetStateAction<ContactFormErrors>>
): Promise<boolean> => {
  let isValid = true;

  const errors: ContactFormErrors = {
    name: '',
    userEmail: '',
    subject: '',
    message: ''
  };

  await Promise.all(
    fieldValidations.map(async (validation) => {
      const {
        fieldName,
        emptyError,
        min,
        minError,
        validator,
        validatorError
      } = validation;
      const value = stateErrorForm[fieldName];

      if (!value) {
        errors[fieldName] = emptyError;
        isValid = false;
      } else if (min && value.length < min) {
        errors[fieldName] = minError!;
        isValid = false;
      } else if (validator && !validator(value)) {
        errors[fieldName] = validatorError!;
        isValid = false;
      }
    })
  );

  if (!isValid) {
    dispatcher(errors);
  }

  return isValid;
};

