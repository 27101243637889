import React from 'react';

import './Loading.css';

const Loading: React.FC = () => {
  return (
    <div className='loader__container'>
      <div className='loader' />
    </div>
  );
};

export default Loading;
