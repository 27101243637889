import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useGlobalContextFunction } from 'context/GlobalProvider';
import { HeadlineText } from 'components/headline-text';
import Map from './components/Map';
import { validateForm } from './utils';

import './ContactUs.css';

// Define a type for the form element
type FormElement = HTMLFormElement;

export type ContactFormValues = {
  name: string;
  userEmail: string;
  subject: string;
  message: string;
};

export type ContactFormErrors = {
  name?: string;
  userEmail?: string;
  subject?: string;
  message?: string;
};

const emailServiceId = 'service_ontix89';
const emailTemplateId = 'template_6ufy66h';
const publicKey = 'fYOv9tKyqaJ0f1rcp';

const ContactUs: React.FC = () => {
  const form = useRef<FormElement | null>(null);

  const [formValues, setFormValues] = useState<ContactFormValues>({
    name: '',
    userEmail: '',
    subject: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState<ContactFormErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Destructuring local state
  const {
    name: nameError,
    userEmail: emailError,
    subject: subjectError,
    message: messageError
  } = formErrors;

  const { name, userEmail, subject, message } = formValues;

  const { setCurrentPage } = useGlobalContextFunction();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const isValidForm = await validateForm(formValues, setFormErrors);

    // Exit when validations not passed
    if (!isValidForm || !form.current) return;

    setIsLoading(true);

    try {
      await emailjs.sendForm(
        emailServiceId,
        emailTemplateId,
        form.current,
        publicKey
      );
      window.alert('PESAN TERKIRIM');
    } catch {
      window.alert('TERJADI KESALAHAN, SILAKAN KIRIM PESAN KEMBALI');
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormValues({
      name: '',
      userEmail: '',
      subject: '',
      message: ''
    });
    setFormErrors({});
  };

  useEffect(() => {
    setCurrentPage('contactus');
  }, [setCurrentPage]);

  return (
    <div className="contactus__container">
      <section>
        <h1>Kontak Kami</h1>
        <p className="contactus__text">
          Untuk pertanyaan atau permintaan dapat dilakukan dengan menghubungi
          kami melalui telepon, email, atau kunjungi office dan workshop kami di
          bawah ini. Kami akan melakukan segala upaya untuk merespons dalam
          waktu 24jam.
        </p>
        <p className="contactus__text">
          Jl. H. Risin Belakang Perum Banjar Wijaya No.74
          <br />
          RT.04 RW.03 Cipete, Pinang-Tangerang
        </p>
        <p className="contactus__text">
          Office: 021 55745595 <br />
          Vino: +62 812-8308-4344 <br />
          Email: devta_printing@yahoo.co.id <br />
          Mon - Sat: 10.00 - 17.00 Sunday: Closed
        </p>
      </section>

      <section className="form__section">
        <h1>Email Kami</h1>
        <form ref={form} onSubmit={handleSubmit}>
          <div className="aboutus__row-input">
            <div className="aboutus__input-wrapper">
              <label>Nama</label>
              <input
                type="text"
                name="name"
                value={name}
                autoComplete='off'
                onChange={handleChange}
              />
              {nameError && <span className="error">{nameError}</span>}
            </div>
            <div className="aboutus__input-wrapper">
              <label>Email</label>
              <input
                type="email"
                name="userEmail"
                value={userEmail}
                autoComplete='off'
                onChange={handleChange}
              />
              {emailError && <span className="error">{emailError}</span>}
            </div>
          </div>
          <div className="aboutus__input-wrapper">
            <label>Subjek</label>
            <input
              type="text"
              name="subject"
              value={subject}
              autoComplete='off'
              onChange={handleChange}
            />
            {subjectError && <span className="error">{subjectError}</span>}
          </div>
          <div className="aboutus__input-wrapper">
            <label>Pesan</label>
            <textarea
              className="message__input"
              name="message"
              value={message}
              onChange={handleChange}
            />
            {messageError && <span className="error">{messageError}</span>}
          </div>
          <div className="contact__btn-wrapper">
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Mohon Tunggu' : 'Kirim'}
            </button>
          </div>
        </form>
      </section>
      <section className="location">
        <h1>Lokasi Kami</h1>
        <Map />
      </section>

      <div className="contact__headline">
        <HeadlineText text="PT. Devta merupakan perusahaan Printing terpercaya selama 20 tahun" />
      </div>
    </div>
  );
};

export default ContactUs;
