import React from 'react';
import { MapPin, Phone, Smartphone, Mail } from 'react-feather';
import FacebookIcon from 'assets/icon/facebook-icon.png';
import LinkedinIcon from 'assets/icon/linkedin-icon.png';
import InstagramIcon from 'assets/icon/instagram-icon.png';

import './Footer.css';

const Footer: React.FC = () => {
  /**
   * Get current year to place on copyright section
   */
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer_container">
      <div className="footer__desc-wrapper">
        <label>PT. DEVTA RAIH CITA</label>
        <p className="footer__desc">
          Percetakan yang bergerak di bidang Digital Printing dan juga Digital
          Offset Printing yang berada di Kota Tangerang. Konsep yang kami buat
          adalah proses online order printing, melayani pemesanan B2C dan B2B
          dengan harga terjangkau. Kami tidak menjamin One Stop Printing/
          Printing terlengkap di kota Tangerang, namun kami memiliki standar
          pelayanan yang ramah, kualitas printing terbaik, cepat dan terjangkau.
        </p>
        <span className="sosial__media-icon">
          <a href="/" target="_blank" rel="noreferrer">
            <img src={FacebookIcon} alt="Facebook" />
          </a>
          <a href="/" target="_blank" rel="noreferrer">
            <img src={LinkedinIcon} alt="LinkedIn" />
          </a>
          <a href="/" target="_blank" rel="noreferrer">
            <img src={InstagramIcon} alt="Instagram" />
          </a>
        </span>
      </div>

      <div className="footer__contact-wrapper">
        <label>Contact Info:</label>
        <div className="contact__icon-wrapper">
          <span className="contact__icon">
            <MapPin size={30} />
            <p>
              Jl. H. Risin Belakang Perum Banjar Wijaya No.74 RT.04 RW.03
              Cipete, Pinang - Tangerang
            </p>
          </span>
          <span className="contact__icon">
            <Phone size={16} />
            <p className="contact__desc">021 55745595</p>
          </span>
          <span className="contact__icon">
            <Smartphone size={16} />
            <p>081211960886 / 081212065755</p>
          </span>
          <span className="contact__icon">
            <Mail size={16} />
            <p>devta.adv@gmail.com</p>
          </span>
        </div>
      </div>

      <div className="copyright">
        <p>
          Copyright <span className="copyright__icon">C</span>
          {` ${currentYear} `}
          <b>PT. Devta Raih Cita</b>, All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
