import React, { useEffect } from 'react';
import { useGlobalContextFunction } from 'context/GlobalProvider';
import { HeadlineText } from 'components/headline-text';
import { clientLogos } from './constants';

import './Clients.css';

const Clients: React.FC = () => {
  const { setCurrentPage } = useGlobalContextFunction();

  useEffect(() => {
    setCurrentPage('clients');
  }, [setCurrentPage]);

  return (
    <div className='clients__container'>
      <p>Berikut adalah beberapa klien yang bekerjasama dengan perusahaan kami:</p>
      <div className='clients__logo-wrapper'>
        {clientLogos.map((logo, index) => (
          <div key={`${logo.name}-${index}`} className='clients__logo-item'>
            <img src={logo.image} alt={logo.alt} />
          </div>
        ))}
      </div>
      <div className='clients__headline-text'>
        <HeadlineText text='PT. Devta merupakan perusahaan Printing terpercaya selama 20 tahun' />
      </div>
    </div>
  );
};

export default Clients;
