import Banner from 'pages/project/assets/banner.png';
import WallpaperBranding from 'pages/project/assets/wallpaper-branding.png';
import CarBranding from 'pages/project/assets/car-branding.png';
import Baliho from 'pages/project/assets/baliho.png';
import LargeSticker from 'pages/project/assets/large-sticker.png';
import PrintingA3 from 'pages/project/assets/printing-a3.png';
import DisplayBanner from 'pages/project/assets/display-banner.png';
import EventOrganizer from 'pages/project/assets/event-orginazer.png';
import Spanduk from 'pages/project/assets/spanduk.png';
import Sablon from 'pages/project/assets/sablon.png';

// ========== Banner images start here ==========
import Banner1 from 'pages/project/assets/banner/banner-1.png';
import Banner2 from 'pages/project/assets/banner/banner-2.png';
import Banner3 from 'pages/project/assets/banner/banner-3.png';
import Banner4 from 'pages/project/assets/banner/banner-4.png';
import Banner5 from 'pages/project/assets/banner/banner-5.png';
import Banner6 from 'pages/project/assets/banner/banner-6.png';
import Banner7 from 'pages/project/assets/banner/banner-7.png';
import Banner8 from 'pages/project/assets/banner/banner-8.png';
import Banner9 from 'pages/project/assets/banner/banner-9.png';
import Banner10 from 'pages/project/assets/banner/banner-10.png';
import Banner11 from 'pages/project/assets/banner/banner-11.png';
// ========== Banner images end here ==========

// ========== Wallpaper Branding images start here ==========
import Wallpaper1 from 'pages/project/assets/wallpaper-branding/wallpaper-1.png';
import Wallpaper2 from 'pages/project/assets/wallpaper-branding/wallpaper-2.png';
import Wallpaper3 from 'pages/project/assets/wallpaper-branding/wallpaper-3.png';
import Wallpaper4 from 'pages/project/assets/wallpaper-branding/wallpaper-4.png';
// ========== Wallpaper Branding images end here ==========

// ========== Car Branding images start here ==========
import CarBranding1 from 'pages/project/assets/car-branding/car-1.png';
import CarBranding2 from 'pages/project/assets/car-branding/car-2.png';
import CarBranding3 from 'pages/project/assets/car-branding/car-3.png';
import CarBranding4 from 'pages/project/assets/car-branding/car-4.png';
import CarBranding5 from 'pages/project/assets/car-branding/car-5.png';
import CarBranding6 from 'pages/project/assets/car-branding/car-6.png';
import CarBranding7 from 'pages/project/assets/car-branding/car-7.png';
// ========== Car Branding images end here ==========

// ========== Baliho images start here ==========
import Baliho1 from 'pages/project/assets/baliho/baliho-1.png';
import Baliho2 from 'pages/project/assets/baliho/baliho-2.png';
import Baliho3 from 'pages/project/assets/baliho/baliho-3.png';
import Baliho4 from 'pages/project/assets/baliho/baliho-4.png';
import Baliho5 from 'pages/project/assets/baliho/baliho-5.png';
import Baliho6 from 'pages/project/assets/baliho/baliho-6.png';
import Baliho7 from 'pages/project/assets/baliho/baliho-7.png';
// ========== Baliho images end here ==========

// ========== Large Sticker images start here ==========
import LargeSticker1 from 'pages/project/assets/large-sticker/LS-1.png';
import LargeSticker2 from 'pages/project/assets/large-sticker/LS-2.png';
import LargeSticker3 from 'pages/project/assets/large-sticker/LS-3.png';
import LargeSticker4 from 'pages/project/assets/large-sticker/LS-4.png';
import LargeSticker5 from 'pages/project/assets/large-sticker/LS-5.png';
import LargeSticker6 from 'pages/project/assets/large-sticker/LS-6.png';
import LargeSticker7 from 'pages/project/assets/large-sticker/LS-7.png';
import LargeSticker8 from 'pages/project/assets/large-sticker/LS-8.png';
import LargeSticker9 from 'pages/project/assets/large-sticker/LS-9.png';
import LargeSticker10 from 'pages/project/assets/large-sticker/LS-10.png';
import LargeSticker11 from 'pages/project/assets/large-sticker/LS-11.png';
import LargeSticker12 from 'pages/project/assets/large-sticker/LS-12.png';
import LargeSticker13 from 'pages/project/assets/large-sticker/LS-13.png';
import LargeSticker14 from 'pages/project/assets/large-sticker/LS-14.png';
// ========== Large Sticker images end here ==========

// ========== Display Banner images start here ==========
import DisplayBanner1 from 'pages/project/assets/display-banner/DB-1.png';
import DisplayBanner2 from 'pages/project/assets/display-banner/DB-2.png';
import DisplayBanner3 from 'pages/project/assets/display-banner/DB-3.png';
import DisplayBanner4 from 'pages/project/assets/display-banner/DB-4.png';
import DisplayBanner5 from 'pages/project/assets/display-banner/DB-5.png';
import DisplayBanner6 from 'pages/project/assets/display-banner/DB-6.png';
import DisplayBanner7 from 'pages/project/assets/display-banner/DB-7.png';
// ========== Display Banner images end here ==========

// ========== Event Organizer images start here ==========
import EO1 from 'pages/project/assets/event-orginazer/EO-1.png';
import EO2 from 'pages/project/assets/event-orginazer/EO-2.png';
import EO3 from 'pages/project/assets/event-orginazer/EO-3.png';
import EO4 from 'pages/project/assets/event-orginazer/EO-4.png';
import EO5 from 'pages/project/assets/event-orginazer/EO-5.png';
import EO6 from 'pages/project/assets/event-orginazer/EO-6.png';
import EO7 from 'pages/project/assets/event-orginazer/EO-7.png';
import EO8 from 'pages/project/assets/event-orginazer/EO-8.png';
import EO9 from 'pages/project/assets/event-orginazer/EO-9.png';
import EO10 from 'pages/project/assets/event-orginazer/EO-10.png';
import EO11 from 'pages/project/assets/event-orginazer/EO-11.png';
import EO12 from 'pages/project/assets/event-orginazer/EO-12.png';
import EO13 from 'pages/project/assets/event-orginazer/EO-13.png';
import EO14 from 'pages/project/assets/event-orginazer/EO-14.png';
import EO15 from 'pages/project/assets/event-orginazer/EO-15.png';
import EO16 from 'pages/project/assets/event-orginazer/EO-16.png';
// ========== Event Organizer images end here ==========

// ========== Spanduk images start here ==========
import Spanduk1 from 'pages/project/assets/spanduk/spanduk-1.png';
import Spanduk2 from 'pages/project/assets/spanduk/spanduk-2.png';
import Spanduk3 from 'pages/project/assets/spanduk/spanduk-3.png';
import Spanduk4 from 'pages/project/assets/spanduk/spanduk-4.png';
import Spanduk5 from 'pages/project/assets/spanduk/spanduk-5.png';
// ========== Spanduk images end here ==========

// ========== Sablon Kaos images start here ==========
import Kaos1 from 'pages/project/assets/sablon-kaos/kaos-1.png';
import Kaos2 from 'pages/project/assets/sablon-kaos/kaos-2.png';
import Kaos3 from 'pages/project/assets/sablon-kaos/kaos-3.png';
import Kaos4 from 'pages/project/assets/sablon-kaos/kaos-4.png';
import Kaos5 from 'pages/project/assets/sablon-kaos/kaos-5.png';
import Kaos6 from 'pages/project/assets/sablon-kaos/kaos-6.png';
import Kaos7 from 'pages/project/assets/sablon-kaos/kaos-7.png';
import Kaos8 from 'pages/project/assets/sablon-kaos/kaos-8.png';
import Kaos9 from 'pages/project/assets/sablon-kaos/kaos-9.png';
import Kaos10 from 'pages/project/assets/sablon-kaos/kaos-10.png';
// ========== Spanduk images end here ==========

// ========== Print on Paper start here ==========
import POP1 from 'pages/project/assets/print-on-paper/POP-1.png';
// ========== Print on Paper end here ==========

interface ProjectData {
  id: number;
  name: string;
  image: string;
  slug: string;
  link: string;
  has_sub_product?: boolean;
  desc1?: string;
  desc2?: string;
  desc3?: string;
  sub_product?: SubProduct[];
  other_images?: string[];
}

export interface SubProduct {
  id: number;
  name: string;
  image?: string;
  desc?: string[];
}

export const projectData: ProjectData[] = [
  {
    id: 1,
    name: 'Banner',
    slug: 'banner',
    image: Banner,
    link: '/project/banner',
    has_sub_product: true,
    desc1:
      'Layanan digital printing untuk mencetak banner dengan kualitas tinggi dan pilihan bahan yang dapat disesuaikan dengan kebutuhan kamu.',
    desc2: 'Terdiri dari Flexy China & Flexy Korea.',
    sub_product: [
      {
        id: 11,
        name: 'Flexy China',
        desc: [
          'Cetak menggunakan mesin Terbaru',
          'High resolution',
          'Bahan flexi yang berkualitas',
          'Bahan tahan air dan tahan panas'
        ]
      },
      {
        id: 12,
        name: 'Flexy Korea',
        desc: [
          'Bahan Flexi Korcin 440gr',
          'Cetak menggunakan mesin Terbaru',
          'High resolution',
          'Bahan flexi yang berkualitas',
          'Bahan tahan air dan tahan panas'
        ]
      }
    ],
    other_images: [
      Banner1,
      Banner2,
      Banner3,
      Banner4,
      Banner5,
      Banner6,
      Banner7,
      Banner8,
      Banner9,
      Banner10,
      Banner11
    ]
  },
  {
    id: 2,
    name: 'Wallpaper Branding',
    slug: 'wallpaper-branding',
    image: WallpaperBranding,
    link: '/project/wallpaper-branding',
    has_sub_product: false,
    desc1:
      'Proses memasang desain grafis, logo, atau pesan merek pada dinding atau permukaan lain di dalam ruangan, menggunakan sticker ataupun flexi.',
    desc2:
      'Ini melibatkan mencetak gambar atau desain khusus pada kertas dinding atau bahan lain yang sesuai dengan ukuran dan kebutuhan ruangan.',
    other_images: [Wallpaper1, Wallpaper2, Wallpaper3, Wallpaper4]
  },
  {
    id: 3,
    name: 'Car Branding',
    slug: 'car-branding',
    image: CarBranding,
    link: '/project/car-branding',
    has_sub_product: true,
    desc1:
      'Car branding adalah layanan cetak stiker khusus yang digunakan untuk menempelkan desain grafis, logo, atau pesan pemasaran pada kendaraan.',
    desc2:
      'Stiker ini dirancang untuk mengubah penampilan visual kendaraan menjadi alat promosi yang berjalan secara mobile.',
    desc3:
      'Car branding sticker biasanya dicetak dengan teknologi digital printing untuk mencapai resolusi tinggi dan warna yang tajam.',
    sub_product: [
      {
        id: 13,
        name: 'Sticker Vinyl Ritrama & Oracal',
        desc: [
          'Bahan Sticker Vinyl Ritrama',
          'Dicetak dengan mesin Mimaki indoor dengan hasil cetak berkualitas tinggi dan tahan lama',
          'Minimum order 1 meter persegi (1x1) jika ukuran di bawah itu tetap dihitung 1 meter persegi',
          'Lebar maksimal 1,5 meter',
          'File di kirim ke email',
          'Proses 1 Hari Langsung Jadi'
        ]
      },
      {
        id: 14,
        name: 'One Way Vision',
        desc: [
          'Jenis sticker berlubang-lubang',
          'Dicetak menggunakan mesin berkualitas tinggi dan tahan lama',
          'Lebar maksimal 1,5 Meter',
          'File di kirim ke email'
        ]
      }
    ],
    other_images: [
      CarBranding1,
      CarBranding2,
      CarBranding3,
      CarBranding4,
      CarBranding5,
      CarBranding6,
      CarBranding7
    ]
  },
  {
    id: 4,
    name: 'Baliho',
    slug: 'baliho',
    image: Baliho,
    link: '/project/baliho',
    has_sub_product: false,
    desc1:
      'Baliho adalah media promosi berupa papan besar yang dipasang di tempat-tempat strategis seperti pinggir jalan, gedung, atau area publik lainnya.',
    desc2:
      'Biasanya terbuat dari bahan vinyl yang tahan cuaca, baliho digunakan untuk menampilkan gambar, pesan pemasaran, atau informasi yang ingin disampaikan kepada khalayak umum.',
    desc3:
      'Baliho memiliki ukuran yang besar sehingga mudah terlihat dan menarik perhatian.',
    other_images: [
      Baliho1,
      Baliho2,
      Baliho3,
      Baliho4,
      Baliho5,
      Baliho6,
      Baliho7
    ]
  },
  {
    id: 5,
    name: 'Large Sticker',
    slug: 'large-sticker',
    image: LargeSticker,
    link: '/project/large-sticker',
    has_sub_product: true,
    desc1:
      'Large Stiker berukuran besar yang dicetak menggunakan teknologi digital.',
    desc2:
      'Stiker ini dapat mencakup gambar, logo, teks, atau desain kustom dengan resolusi tinggi dan warna yang tajam.',
    desc3:
      'Dengan kemampuan digital printing, stiker besar ini dapat menghadirkan hasil cetakan yang detail, tahan lama, dan menarik, sehingga cocok digunakan untuk keperluan promosi, branding, atau dekorasi yang membutuhkan tampilan yang mencolok dan eye-catching.',
    sub_product: [
      {
        id: 15,
        name: 'Sticker Vinyl China',
        desc: [
          'Bahan Sticker Vinyl China',
          'Dicetak dengan mesin Mimaki indoor dengan hasil cetak berkualitas tinggi dan tahan lama',
          'Minimum order 1 meter persegi (1x1) jika ukuran di bawah itu tetap dihitung 1 meter persegi',
          'Lebar maximum 1,5 meter',
          'File di kirim ke email',
          'Proses 1 Hari Langsung Jadi'
        ]
      },
      {
        id: 16,
        name: 'Sticker Hologram',
        desc: [
          'Dicetak menggunakan mesin berkualitas tinggi dan tahan lama',
          'Tahan air dan tidak mudah sobek',
          'Lebar maksimal 1,20 meter',
          'File di kirim ke email'
        ]
      },
      {
        id: 17,
        name: 'One Way Vision',
        desc: [
          'Jenis sticker berlubang-lubang',
          'Dicetak menggunakan mesin berkualitas tinggi dan tahan lama',
          'Lebar maksimal 1,5 Meter',
          'File di kirim ke email'
        ]
      },
      {
        id: 18,
        name: 'Sticker Vinyl Ritrama & Oracal',
        desc: [
          'Bahan Sticker Vinyl Ritrama',
          'Dicetak dengan mesin Mimaki indoor dengan hasil cetak berkualitas tinggi dan tahan lama',
          'Minimum order 1 meter persegi (1x1) jika ukuran di bawah itu tetap dihitung 1 meter persegi',
          'Lebar maksimal 1,5 meter',
          'File di kirim ke email',
          'Proses 1 Hari Langsung Jadi'
        ]
      },
      {
        id: 19,
        name: 'Sticker Sandblast',
        desc: [
          'Sticker yang memiliki tekstur khusus sehingga meberikan efek buram',
          'Dicetak menggunakan mesin berkualitas tinggi dan tahan lama',
          'Lebar maksimal 1,2 meter',
          'File di kirim ke email'
        ]
      }
    ],
    other_images: [
      LargeSticker1,
      LargeSticker2,
      LargeSticker3,
      LargeSticker4,
      LargeSticker5,
      LargeSticker6,
      LargeSticker7,
      LargeSticker8,
      LargeSticker9,
      LargeSticker10,
      LargeSticker11,
      LargeSticker12,
      LargeSticker13,
      LargeSticker14
    ]
  },
  {
    id: 6,
    name: 'Print on Paper',
    slug: 'print-on-paper',
    image: PrintingA3,
    link: '/project/printing-on-paper',
    has_sub_product: true,
    desc1:
      'Printing on Paper adalah layanan cetakan sticker ataupun kertas menggunakan ukuran A3 yang lebih kecil, biasanya digunakan untuk keperluan promosi, pameran, atau dekorasi ruangan.',
    desc2:
      'Proses digital printing memungkinkan cetakan dengan resolusi tinggi, detail yang tajam, dan warna yang kaya sehingga menghasilkan gambar yang menarik dan profesional.',
    desc3:
      'Large poster ini sering digunakan untuk menarik perhatian dan memberikan pesan yang kuat kepada target audiens.',
    sub_product: [
      {
        id: 20,
        name: 'Sticker Chromo',
        desc: [
          'Bahan Chromo A3',
          'Ukuran kertas 32 x 48 cm',
          'Area Maksimal Cetak 30 x 46 cm',
          'Dicetak dengan hasil cetak berkualitas',
          'Minimum order 5 Lembar',
          'File design di kirim ke email',
          'Proses 1 hari langsung jadi'
        ]
      },
      {
        id: 21,
        name: 'Sticker HVS',
        desc: [
          'Bahan HVS ukuran A3',
          'Ukuran kertas 32 x 48 cm',
          'Area Maksimal Cetak 30 x 46 cm',
          'Dicetak dengan hasil cetak berkualitas',
          'Sticker bisa ditulis (tanpa laminasi)',
          'Minimum order 5 Lembar',
          'File design di kirim ke email',
          'Proses 1 hari langsung jadi'
        ]
      },
      {
        id: 22,
        name: 'Sticker Gold',
        desc: [
          'Bahan Silver Gold A3 Quantac',
          'Tahan terhadap Air',
          'Ukuran kertas 32 x 48 cm',
          'Area Maksimal Cetak 30 x 46 cm',
          'Dicetak dengan hasil cetak berkualitas',
          'Minimum order 5 Lembar',
          'File design di kirim ke email',
          'Harga Normal berlaku untuk cutting size terkecil 3x3cm atau 9cm2',
          'Proses 1 - 2 hari langsung jadi'
        ]
      },
      {
        id: 23,
        name: 'Sticker Hologram',
        desc: [
          'Bahan Hologram A3 Quantac',
          'Ukuran kertas 32 x 48 cm',
          'Area Maksimal Cetak 30 x 46 cm',
          'Dicetak dengan hasil cetak berkualitas',
          'Tahan terhadap Air',
          'Minimum order 5 Lembar',
          'File design di kirim ke email',
          'Harga Normal berlaku untuk cutting size terkecil 3x3cm atau 9cm2',
          'Proses 1 - 2 Hari Langsung Jadi'
        ]
      },
      {
        id: 24,
        name: 'Ritrama UV Gel',
        desc: [
          'Ukuran kertas 32 x 48 cm',
          'Area Maksimal Cetak 30 x 46 cm',
          'Dicetak menggunakan mesin UV gel dengan hasil cetak berkualitas tinggi dan bertekstur',
          'Minimum order 5 Lembar',
          'File design di kirim ke email',
          'Proses 1 Hari Langsung Jadi'
        ]
      },
      {
        id: 30,
        name: 'Jenis Kertas',
        desc: ['HVS', 'Art Carton', 'Art Paper']
      }
    ],
    other_images: [POP1]
  },
  {
    id: 7,
    name: 'Display Banner',
    slug: 'display-banner',
    image: DisplayBanner,
    link: '/project/display-banner',
    has_sub_product: true,
    desc1: 'Layanan untuk membuat dan mencetak banner promosi yang menarik.',
    desc2:
      'Dengan menggunakan teknologi digital, kami mencetak banner dengan gambar, teks, dan desain yang jelas dan berwarna-warni.',
    desc3:
      'Banner ini dirancang untuk menarik perhatian dan mempromosikan produk, layanan, atau acara dengan tampilan yang profesional.',
    sub_product: [
      {
        id: 25,
        name: 'X Banner',
        desc: [
          'Ukuran 60 x 160 cm',
          'Sudah termasuk Standing X Banner dan Cetakan'
        ]
      },
      {
        id: 26,
        name: 'Y Banner',
        desc: [
          'Ukuran 60 x 160 cm',
          'Sudah termasuk Standing X Banner dan Cetakan'
        ]
      }
    ],
    other_images: [
      DisplayBanner1,
      DisplayBanner2,
      DisplayBanner3,
      DisplayBanner4,
      DisplayBanner5,
      DisplayBanner6,
      DisplayBanner7
    ]
  },
  {
    id: 8,
    name: 'Event Organizer',
    slug: 'event-organizer',
    image: EventOrganizer,
    link: '/project/event-organizer',
    has_sub_product: true,
    desc1:
      'Layanan yang menyediakan bantuan profesional dalam perencanaan, pengorganisasian, dan pelaksanaan acara.',
    desc2:
      'Event organizer bertanggung jawab untuk mengkoordinasikan berbagai aspek acara, termasuk pemilihan lokasi, pengelolaan vendor, manajemen anggaran, pengaturan teknis, dan koordinasi keseluruhan acara.',
    desc3:
      'Mereka bekerja sama dengan klien untuk memastikan acara berjalan lancar, sesuai dengan visi dan tujuan yang diinginkan.',
    other_images: [
      EO1,
      EO2,
      EO3,
      EO4,
      EO5,
      EO6,
      EO7,
      EO8,
      EO9,
      EO10,
      EO11,
      EO12,
      EO13,
      EO14,
      EO15,
      EO16
    ]
  },
  {
    id: 9,
    name: 'Spanduk',
    slug: 'spanduk',
    image: Spanduk,
    link: '/project/spanduk',
    has_sub_product: false,
    desc1:
      'Layanan Cetak spanduk manual printing dengan biaya yang lebih terjangkau solusi promosi yang efektif dan mengesankan.',
    desc2:
      'Mencetak spanduk berkualitas tinggi dengan warna yang kaya, detail yang tajam, dan kejelasan yang luar biasa.',
    other_images: [Spanduk1, Spanduk2, Spanduk3, Spanduk4, Spanduk5]
  },
  {
    id: 10,
    name: 'Sablon Kaos',
    slug: 'sablon-kaos',
    image: Sablon,
    link: '/project/sablon-kaos',
    has_sub_product: true,
    desc1:
      'Jasa sablon kaos Devta menawarkan kesempatan bagi pelanggan untuk memenuhi kebutuhan bisnis atau perorangan melalui desain kaos yang kreatif.',
    desc2:
      'Kami menggunakan teknik sablon manual yang berkualitas untuk memastikan bahwa kaos yang dihasilkan memiliki tampilan yang menarik dan tahan lama.',
    sub_product: [
      {
        id: 30,
        name: 'Jenis Bahan',
        desc: [
          'Katun Combed: Kain yang terbuat dari serat kapas murni alami dengan karakteristik halus, nyaman, dan menyerap keringat. Jenisnya terdiri dari combed 30s, combed 24s & combed 20s. Masing-masing ketebalannya berbeda',
          'Katun Bamboo: Jenis bahan ini terbentuk dari campuran serat katun dan serat bamboo. Keduanya merupakan serat alami sehingga pasti aman buat kulit. Tekstur kain sangat lembut, anti bakteri dan daya serap sangat tinggi',
          'Katun Carded: Memiliki serat benang yang sedikit lebih kasar jika dibandingkan dengan kain cotton combed'
        ]
      },
      {
        id: 31,
        name: 'Jenis Tinta',
        desc: [
          'Rubber: Jenis tinta sablon yang memiliki sifat menyatu dengan air atau bisa menggunakan air. Sablon ini memiliki sifat elastis.',
          'Superwhite: Jenis tinta yang meresap dengan baik di dalam pori pori dan serat kain sehingga hasil sablon terasa lembut dan menyatu dengan kainnya',
          'Discharge: Jenis tinta yang dapat mengubah warna dasar kain menjadi sama dengan warna tinta yang digunakan. Seringkali disebut dengan istilah cabut warna',
          'Plastisol: Jenis tinta oil based atau berbasis minyak. Tinta sablon plastisol bersifat tahan lama dan kuat menempel pada kain baju.'
        ]
      }
    ],
    other_images: [
      Kaos1,
      Kaos2,
      Kaos3,
      Kaos4,
      Kaos5,
      Kaos6,
      Kaos7,
      Kaos8,
      Kaos9,
      Kaos10
    ]
  }
];
