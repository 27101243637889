import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import classNames from 'classnames';
import { useWindowResize } from 'hooks';
import { clientLogos } from 'pages/clients/constants';

import styles from './ClientsSlider.module.css';
import 'swiper/css';
import 'swiper/css/navigation';

const ClientsSlider: React.FC = () => {
  const { width } = useWindowResize();

  const isDesktop = width >= 768;

  const slidesPerView = isDesktop ? 5 : 3;

  return (
    <div className={styles['client__container']}>
      <h1 className={styles['client__title']}>Klien Kami</h1>
      <Swiper
        loop
        autoplay={{ delay: 3000 }}
        navigation={true}
        modules={[Navigation, Autoplay]}
        slidesPerView={slidesPerView}
        spaceBetween={10}
      >
        {clientLogos.map((logo, index) => (
          <SwiperSlide key={`logo-${index + 1}`}>
            <div className={styles['client__item']}>
              <img 
                src={logo.image} 
                className={classNames({
                  [styles['client__item-img']]: true,
                  [styles['bfi']]: logo.id === 3,
                  [styles['citrakarya']]: logo.id === 7,
                  [styles['bintaro-parkview']]: logo.id === 12,
                  [styles['vivo']]: logo.id === 16,
                  [styles['stmik-global']]: logo.id === 23,
                  [styles['djp']]: logo.id === 25,
                })}
                alt={logo.alt} 
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ClientsSlider;
