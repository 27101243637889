/**
 * Truncates a string if it exceeds a specified maximum length per word.
 * @param {string} string - The input string to be truncated.
 * @param {number} maxLength - The maximum length per word before truncation.
 * @returns {string} The truncated string.
 */
export default function truncateString(
  string: string,
  maxLength: number
): string {
  return string.substring(0, maxLength)
}
