import Workshop1 from 'pages/workshop/assets/1-ws.png';
import Workshop2 from 'pages/workshop/assets/2-ws.png';
import Workshop3 from 'pages/workshop/assets/3-ws.png';
import Workshop4 from 'pages/workshop/assets/4-ws.png';
import Workshop5 from 'pages/workshop/assets/5-ws.png';
import Workshop6 from 'pages/workshop/assets/6-ws.png';
import Workshop7 from 'pages/workshop/assets/7-ws.png';
import Workshop8 from 'pages/workshop/assets/8-ws.png';
import Workshop9 from 'pages/workshop/assets/9-ws.png';
import Workshop10 from 'pages/workshop/assets/10-ws.png';
import Workshop11 from 'pages/workshop/assets/11-ws.png';
import Workshop12 from 'pages/workshop/assets/12-ws.png';
import Workshop13 from 'pages/workshop/assets/13-ws.png';
import Workshop14 from 'pages/workshop/assets/14-ws.png';
import Workshop15 from 'pages/workshop/assets/15-ws.png';

type WorkshopType = {
  id: number;
  image: string;
  name: string;
};

export const workshopData: WorkshopType[] = [
  {
    id: 1,
    image: Workshop1,
    name: 'Workshop-1',
  },
	{
    id: 2,
    image: Workshop2,
    name: 'Workshop-2',
  },
	{
    id: 3,
    image: Workshop3,
    name: 'Workshop-3'
  },
	{
    id: 4,
    image: Workshop4,
    name: 'Workshop-4'
  },
	{
    id: 5,
    image: Workshop5,
    name: 'Workshop-5'
  },
	{
    id: 6,
    image: Workshop6,
    name: 'Workshop-6'
  },
	{
    id: 7,
    image: Workshop7,
    name: 'Workshop-7'
  },
	{
    id: 8,
    image: Workshop8,
    name: 'Workshop-8'
  },
  {
    id: 9,
    image: Workshop9,
    name: 'Workshop-9'
  },
	{
    id: 10,
    image: Workshop10,
    name: 'Workshop-10'
  },
	{
    id: 11,
    image: Workshop11,
    name: 'Workshop-11'
  },
	{
    id: 12,
    image: Workshop12,
    name: 'Workshop-12'
  },
	{
    id: 13,
    image: Workshop13,
    name: 'Workshop-13'
  },
	{
    id: 14,
    image: Workshop14,
    name: 'Workshop-14'
  },
	{
    id: 15,
    image: Workshop15,
    name: 'Workshop-15'
  },
];
