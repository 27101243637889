import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useWindowResize } from 'hooks';
import { servicesList } from './constants';
import truncateString from 'utils/truncateString';
import './ServicesSection.css';

const ServicesSection: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowResize();

  /** Determine of device user is moblie */
  const isMobile = width < 767;
  /** Determine of medium device such as table, ipad, etc */
  const isMediumDevice = (width >= 767) && (width <= 1236);

  const device = () => {
    if (isMobile) return 1;
    if (isMediumDevice) return [1, 2];
    return [1,2,3,4];
  };

  const [serviceOpen, setServiceOpen] = useState<number | number[]>(device);

  const handleCollapse = (idsToOpen: number[]) => {
    // Guard
    if (!Array.isArray(serviceOpen)) return;

    setServiceOpen(serviceOpen.includes(idsToOpen[0]) ? [] : idsToOpen);
  };

  const handleClick = (serviceId: number) => {
    if (isMobile) {
      if (serviceOpen === serviceId) {
        return setServiceOpen(0);
      }
      return setServiceOpen(serviceId);
    }

    if (isMediumDevice) {
      // Guard
      if (!Array.isArray(serviceOpen)) return;

      if (serviceId >= 1 && serviceId <= 2) {
        const idsToOpen = [1, 2];
        return handleCollapse(idsToOpen);
      }

      if (serviceId >= 3 && serviceId <= 4) {
        const idsToOpen = [3, 4];
        return handleCollapse(idsToOpen);
      }

      if (serviceId >= 5 && serviceId <= 6) {
        const idsToOpen = [5, 6];
        return handleCollapse(idsToOpen);
      }

      if (serviceId >= 7 && serviceId <= 8) {
        const idsToOpen = [7, 8];
        return handleCollapse(idsToOpen);
      }

      if (serviceId >= 9 && serviceId <= 10) {
        const idsToOpen = [9, 10];
        return handleCollapse(idsToOpen);
      }
    }
    // Guard
    if (!Array.isArray(serviceOpen)) return;

    if (serviceId >= 1 && serviceId < 5) {
      const idsToOpen = [1, 2, 3, 4];
      return handleCollapse(idsToOpen);
    }

    if (serviceId >= 5 && serviceId <= 8) {
      const idsToOpen = [5, 6, 7, 8];
      return handleCollapse(idsToOpen);
    }

    if (serviceId > 8) {
      const idsToOpen = [9, 10, 11];
      return handleCollapse(idsToOpen);
    }
  };

  return (
    <div className="services__container">
      <h1>Layanan Kami</h1>
      <div className="services__list-wrapper">
        {servicesList.map((service) => (
          <div key={service.id} className="services__list">
            <div
              className="services__list-header"
              onClick={() => handleClick(service.id)}
            >
              <span className="service__title">{service.title}</span>
              <span>
                {(Number(serviceOpen) && serviceOpen === service.id) ||
                (Array.isArray(serviceOpen) &&
                  serviceOpen.includes(service.id)) ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )}
              </span>
            </div>
            {((Number(serviceOpen) && serviceOpen === service.id) ||
              (Array.isArray(serviceOpen) &&
                serviceOpen.includes(service.id))) && (
              <>
                <div className="services__list-content">{`${truncateString(
                  service.desc,
                  145
                )}...`}</div>
                <div className="service__btn">
                  <span onClick={() => navigate(service.link)}>Lihat Detail</span></div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
