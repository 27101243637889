import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { X, Plus, Minus } from 'react-feather';
import classNames from 'classnames';
import { useScrollBlock } from 'hooks';
import { useGlobalContextState } from 'context/GlobalProvider';
import {
  NavMenu,
  SubMenu,
  aboutUsSubMenu,
  navMenu,
  projectSubMenu
} from '../constants';

import './LeftMenu.css';

interface LeftMenuProps {
  visible: boolean;
  /**
   * Duration for slide animation (ms)
   */
  slideDuration?: number;
  /** Handle menu scrolling
   * In case you need menu to scrolling
   */
  lockScrolling?: boolean;
  /**
   * Callback called when the close button or mask is clicked
   */
  onClose?: Function;
}

const LeftMenu: React.FC<React.PropsWithChildren<LeftMenuProps>> = ({
  visible,
  slideDuration = 0,
  lockScrolling = true,
  onClose
}) => {
  const navigate = useNavigate();

  const { currentPage } = useGlobalContextState();

  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [menuAnimation, setMenuAnimation] = useState<boolean>(false);
  const [subMenuActive, setSubMenuActive] = useState<number[]>([]);
  // tell us where animation is at
  // this state only consider when `animated` props is true
  // 1: the menu not show in the screen. which mean `visible` props is false
  // 2: the menu show in the Screen. which mean visible is true. then the menu ready to animating close
  const [animationStep, setAnimationStep] = useState<1 | 2>(1);

  // Hook to block/allow scroll
  const [scrollBlock, allowScroll] = useScrollBlock();

  const handleClose = (isNavigate = false) => {
    if (!!isNavigate && onClose) {
      onClose();
      setSubMenuActive([]);
      return;
    }

    const menuBody = document.querySelector('.menu__body');

    if (menuBody && !isNavigate) {
      menuBody.classList.add('disabled');
    }

    setTimeout(() => {
      if (onClose) {
        onClose();
        setSubMenuActive([]);
      }
    }, 400);
  };

  const handleToggleSubMenu = (menu: NavMenu) => {
    if (subMenuActive.length === 0) {
      return setSubMenuActive([menu.id]);
    }

    if (subMenuActive.includes(menu.id)) {
      return setSubMenuActive((prevState) =>
        prevState.filter((item) => item !== menu.id)
      );
    }

    return setSubMenuActive((prevState) => prevState?.concat(menu.id));
  };

  const handleNavigate = (menu: NavMenu | SubMenu) => {
    if (menu?.link) {
      handleClose(true);
      navigate(menu?.link);
      return;
    }
    return handleToggleSubMenu(menu);
  };

  useEffect(() => {
    let timeout: any;

    if (!visible) {
      setMenuAnimation(visible);

      if (animationStep === 2) {
        timeout = setTimeout(() => {
          // Allow scroll when modal closed
          allowScroll();

          setVisibleMenu(visible);

          // reset animation step to be 1 again
          setAnimationStep(1);
        }, slideDuration);
      } else {
        setVisibleMenu(visible);
      }
      return;
    }

    setVisibleMenu(visible);

    // This if logic works the same as above, only wait for setTimeout if this menu
    // is animated

    // Lock scrolling default true, if true we lock the scrolling.
    timeout = setTimeout(() => {
      if (lockScrolling) {
        // Prevent scrolling when modal is open
        scrollBlock();
      }

      // set animating step to be 2
      setAnimationStep(2);
      setMenuAnimation(visible);
    }, slideDuration);

    const menuBody = document.querySelector('.menu__body');

    // if this menu (or lets say, page/component that called this modal) is unmounted
    // without triggering 'close' state to this menu, set document.body overflow to normal
    return () => {
      menuBody?.classList.remove('disabled');
      // Allow scroll on un-mounting component
      allowScroll();

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    visible,
    lockScrolling,
    slideDuration,
    animationStep,
    allowScroll,
    scrollBlock
  ]);

  useEffect(() => {
    if (aboutUsSubMenu.includes(currentPage)) {
      return setSubMenuActive([2]);
    }

    if (projectSubMenu.includes(currentPage)) {
      return setSubMenuActive([3]);
    }

    return setSubMenuActive([]);
  }, [currentPage, visible]);

  if (!visibleMenu) return null;

  return ReactDOM.createPortal(
    <div
      className={classNames({
        menu: true,
        menu__visible: menuAnimation
      })}
    >
      <div className="menu__mask" onClick={() => handleClose()} />
      <div className="menu__body">
        <div className="menu__wrapper">
          <div className="menu__header">
            <h3>Menu</h3>
            <span onClick={() => handleClose()}>
              <X />
            </span>
          </div>
          {navMenu.map((menu) => (
            <div key={menu.id} className="menu__list-wrapper">
              <div
                className={classNames('menu__list', {
                  active: currentPage === menu.slug
                })}
              >
                <span onClick={() => handleNavigate(menu)}>{menu.name}</span>
                {!!menu?.subMenu && (
                  <span onClick={() => handleToggleSubMenu(menu)}>
                    {subMenuActive?.includes(menu.id) ? <Minus /> : <Plus />}
                  </span>
                )}
              </div>
              <div
                className={classNames('sub__menu', {
                  active: subMenuActive && !!menu?.subMenu
                })}
              >
                {subMenuActive?.includes(menu.id) &&
                  menu?.subMenu?.map((sub, index) => (
                    <div
                      key={index}
                      className={classNames('sub__menu-list', {
                        active: currentPage === sub.slug
                      })}
                    >
                      <span onClick={() => handleNavigate(sub)}>
                        {sub.name}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default LeftMenu;
