export interface NavMenu {
  id: number;
  name: string;
  slug?: string;
  link?: string;
  subMenu?: SubMenu[];
};

export interface SubMenu {
  id: number;
  name: string;
  slug: string;
  link: string;
}

export const aboutUsSubMenu = ['philosophy', 'advantage'];
export const projectSubMenu = [
  'project',
  'banner',
  'wallpaper-branding',
  'car-branding',
  'baliho',
  'large-sticker',
  'print-on-paper',
  'display-banner',
  'event-organizer',
  'spanduk',
  'sablon-kaos'
];

export const navMenu: NavMenu[] = [
  {
    id: 1,
    name: 'home',
    slug: 'home',
    link: '/'
  },
  {
    id: 2,
    name: 'about us',
    slug: 'about-us',
    subMenu: [
      {
        id: 7,
        name: 'filosofi',
        slug: 'philosophy',
        link: '/filosofi',
      },
      {
        id: 8,
        name: 'keunggulan devta',
        slug: 'advantage',
        link: '/keunggulan',
      },
    ]
  },
  {
    id: 3,
    name: 'projects',
    slug: 'projects',
    subMenu: [
      {
        id: 9,
        name: 'semua',
        slug: 'project',
        link: '/project'
      },
      {
        id: 10,
        name: 'banner',
        slug: 'banner',
        link: '/project/banner'
      },
      {
        id: 11,
        name: 'wallpaper branding',
        slug: 'wallpaper-branding',
        link: '/project/wallpaper-branding'
      },
      {
        id: 12,
        name: 'car branding',
        slug: 'car-branding',
        link: '/project/car-branding'
      },
      {
        id: 13,
        name: 'baliho',
        slug: 'baliho',
        link: '/project/baliho'
      },
      {
        id: 14,
        name: 'large sticker',
        slug: 'large-sticker',
        link: '/project/large-sticker'
      },
      {
        id: 15,
        name: 'print on paper',
        slug: 'print-on-paper',
        link: '/project/print-on-paper'
      },
      {
        id: 16,
        name: 'display banner',
        slug: 'display-banner',
        link: '/project/display-banner'
      },
      {
        id: 17,
        name: 'event organizer',
        slug: 'event-organizer',
        link: '/project/event-organizer'
      },
      {
        id: 18,
        name: 'spanduk',
        slug: 'spanduk',
        link: '/project/spanduk'
      },
      {
        id: 19,
        name: 'sablon kaos',
        slug: 'sablon-kaos',
        link: '/project/sablon-kaos'
      }
    ]
  },
  {
    id: 4,
    name: 'workshop',
    slug: 'workshop',
    link: '/workshop'
  },
  {
    id: 5,
    name: 'clients',
    slug: 'clients',
    link: '/clients'
  },
  {
    id: 6,
    name: 'contact us',
    slug: 'contactus',
    link: '/contactus'
  }
];
