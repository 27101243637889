import React from 'react';
import WhatsAppImg from './assets/whatsapp.png';

import './WhatsAppWidget.css';

const WhatsAppWidget: React.FC = () => {
  return (
    <a 
      href='https://api.whatsapp.com/send?phone=6281283084344'
      target='_blank'
      rel='noreferrer'
      className='whatsapp_link'
    >
      <div className='whatsapp__widget-container'>
        <div>Need Help? Chat with us</div>
        <img src={WhatsAppImg} alt='whatsapp' />
      </div>
    </a>
  );
};

export default WhatsAppWidget;
